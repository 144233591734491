import React from 'react';
import { PageProps, graphql } from 'gatsby';

import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import Layout, { LayoutOptions, HeroSectionColors } from '../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../localization/translations';
// import { useLocaleContext } from '../../context/Locale';
import ExternalLink from '../../components/ExternalLink/ExternalLink';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../config/RouteKeys';
import CommonData from '../../data/common/common';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import { IconTypes } from '../../components/Icon/Icon';
import './style.scss';

type Props = PageProps & WithTranslation;

const CCP: React.FC<Props> = (props: Props) => {
  const { t, data } = props;
  // const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('seo.title'),
    },
    page: {
      title: t('seo.title'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.SKY_BLUE,
        heroImage: {
          fileName: 'hero-image-ccp.jpg',
          alt: 'Image',
        },
        blockText: t('cashback.title'),
        shouldBeDigiBlock: true,
      },
    },
  };

  // const renewImageUrl = (data as any).renew.edges.find((edge: any) =>
  //   edge.node.name.includes(`-${activeLocaleTag}`)
  // ).node.publicURL;

  const cachbackImageUrl = (data as any).cashback.edges[0].node.publicURL;

  return (
    <Layout options={layoutOptions} className="CCP">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-100">
            <div className="CCP__generic-gap">
              <p>
                <Trans
                  t={t}
                  i18nKey="index.body"
                  values={{ phone: CommonData.tollFreeCustomerServiceNumber.mortgageServicing }}
                >
                  <ExternalLink
                    href={`tel:${CommonData.tollFreeCustomerServiceNumber.mortgageServicing}`}
                    aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:tollFree`)} ${
                      CommonData.tollFreeCustomerServiceNumber.mortgageServicing
                    }`}
                  />
                </Trans>
              </p>
              {/* <div className="small-img-container">
                <img src={cachbackImageUrl} alt={t('index.cashback.heading')} />
              </div> */}
            </div>
            {/* <div className="CCP__generic-gap">
              <h2 className="h4">{t('index.cashback.heading')}</h2>
              <p>
                <Trans t={t} i18nKey="index.cashback.body" />
              </p>
              <ButtonLink
                to={getPathFromRouteKey(RouteKeys.CCP.CASHBACK)}
                styleOptions={{ isInline: true }}
                iconOptions={{ icon: IconTypes.ARROW_NEXT }}
              >
                {t('index.cashback.buttonLabel')}
              </ButtonLink>
            </div> */}
            {/* <div className="CCP__generic-gap">
              <div className="row">
                <div className="column column-30">
                  <div className="small-img-container">
                    <img src={renewImageUrl} alt="Renew" />
                  </div>
                  <div className="mb-2">
                    <h6 className="mb-1">{t('index.renew.heading')}</h6>
                    <p className="small">
                      <Trans t={t} i18nKey="index.renew.body" />
                    </p>
                  </div>
                  <ButtonLink
                    to={getPathFromRouteKey(RouteKeys.CCP.RENEW)}
                    styleOptions={{ isInline: true }}
                    iconOptions={{ icon: IconTypes.ARROW_NEXT }}
                  >
                    {t('index.renew.buttonLabel')}
                  </ButtonLink>
                </div>
                <div className="column column-30">
                  <div className="small-img-container">
                    <img src={cachbackImageUrl} alt="Cashback" />
                  </div>
                  <div className="mb-2">
                    <h6 className="mb-1">{t('index.cashback.heading')}</h6>
                    <p className="small">
                      <Trans t={t} i18nKey="index.cashback.body" />
                    </p>
                  </div>
                  <ButtonLink
                    to={getPathFromRouteKey(RouteKeys.CCP.CASHBACK)}
                    styleOptions={{ isInline: true }}
                    iconOptions={{ icon: IconTypes.ARROW_NEXT }}
                  >
                    {t('index.cashback.buttonLabel')}
                  </ButtonLink>
                </div>
              </div>
            </div> */}
          </section>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    renew: allFile(filter: { relativePath: { regex: "/ccp/renew/" }, ext: { eq: ".jpg" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }

    cashback: allFile(filter: { relativePath: { regex: "/ccp/cashback.jpg/" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`;

export default withTranslation(LocaleNameSpaceKeys.CCP)(CCP);
